import { zodResolver } from '@hookform/resolvers/zod';
import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import Icon from '~/components/Icon/Icon';
import MainSidebar from '~/components/MainSidebar/MainSidebar';
import { ModalType } from '~/components/Modal/interface';
import Modal from '~/components/Modal/Modal';
import { useGlobal } from '~/hooks/useGlobal';
import { useModal } from '~/hooks/useModal';
import { useRewardedPromocodesStatus } from '~/hooks/useRewardedPromocodesStatus';
import MobileHeaderLayout from '~/pages/Main/MobileHeader/MobileHeaderLayout';
import MobileHeaderTitle from '~/pages/Main/MobileHeader/MobileHeaderTitle';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';
import { textRegex } from '~/utils/regexp';

import styles from './PersonalData.module.scss';

type Fields = 'name' | 'e-mail' | 'phone-number';

interface FieldData {
  type: Fields;
  title: string;
  defaultValue: string;
  error?: ReactNode;
  placeholder?: string;
}

export const PersonalDataVariant1 = observer(() => {
  const { t } = useTranslation();
  const { isMobile } = useGlobal();
  const [modal, setModal] = useState<Nullable<FieldData>>(null);
  const { openModal } = useModal();

  useRewardedPromocodesStatus();

  const fields = useMemo<Record<Fields, FieldData>>(
    () =>
      ({
        name: {
          type: 'name',
          title: t('yourName'),
          defaultValue: userStore.fullName,
          error: t('pleaseEnterName'),
          placeholder: t('messageReceiverNamePlaceholder'),
        },
        'e-mail': {
          type: 'e-mail',
          title: t('email'),
          defaultValue: userStore.email,
          error: t('pleaseEnterEmail'),
          placeholder: t('messageEmailAddressPlaceholder'),
        },
        'phone-number': {
          type: 'phone-number',
          title: t('phoneNumber'),
          defaultValue: mainStore.prettyPhoneNumber(userStore.phone),
        },
      }) as const,
    [userStore.fullName, userStore.email, userStore.phone, t],
  );

  const deleteAccount = () => openModal(ModalType.DeleteAccount);

  const getFieldProps = (type: Fields) => {
    if (type === 'phone-number') {
      return fields[type];
    }

    return {
      ...fields[type],
      onClick: () => setModal(fields[type]),
    };
  };

  const handleSubmit = (data: string, type: Fields) => {
    switch (type) {
      case 'name': {
        const { firstName, middleName, lastName } =
          userStore.splitFullName(data);

        if (firstName) {
          userStore
            .updatePersonalData({
              first_name: firstName,
              middle_name: middleName,
              last_name: lastName,
              email: userStore.personalData.email,
            })
            .catch(console.error);
        }
        break;
      }
      case 'e-mail': {
        userStore.updatePersonalData({ email: data }).catch(console.error);
        break;
      }
    }

    setModal(null);
  };

  return (
    <>
      <MobileHeaderLayout
        content={<MobileHeaderTitle text={t('personalData')} />}
      />
      <div className={classes(['content personal-data', styles.personalData])}>
        <aside className="sidebar">
          <MainSidebar />
        </aside>
        <main className="container">
          {!isMobile && (
            <section className={classes(['header', styles.header])}>
              <Icon type="arrow" size={24} className="icon__rotate-180" />
              <div className="title">{t('personalData')}</div>
            </section>
          )}
          <PersonalDataField {...getFieldProps('name')} />
          <PersonalDataField {...getFieldProps('e-mail')} />
          <PersonalDataField {...getFieldProps('phone-number')} />
          <section className={classes(['delete-accaunt', styles.delete])}>
            <button className="button _bordered" onClick={deleteAccount}>
              <Icon type="trash" size={24} />
              <div className="btn">{t('deleteAccount')}</div>
            </button>
          </section>
        </main>
      </div>
      {modal && (
        <ChangeDataModal
          {...modal}
          onSubmit={handleSubmit}
          onClose={() => setModal(null)}
        />
      )}
    </>
  );
});

interface PersonalDataFieldProps extends FieldData {
  onClick?(): void;
}

const PersonalDataField = ({
  title,
  defaultValue,
  error,
  onClick,
}: PersonalDataFieldProps) => {
  return (
    <div
      onClick={onClick}
      className={classes([styles.field, onClick && styles.action])}
    >
      <div>
        {error && !defaultValue ? (
          <div className={styles.field__error}>{error}</div>
        ) : (
          <>
            <div className={styles.field__label}>{title}</div>
            <div className={classes([styles.field__value, 'phoneNumber'])}>
              {defaultValue || <>&nbsp;</>}
            </div>
          </>
        )}
      </div>
      {onClick && <Icon type="chevron" size={24} />}
    </div>
  );
};

interface ChangeDataModalProps extends FieldData {
  onSubmit(data: string, type: Fields): void;
  onClose(): void;
}

const ChangeDataModal = ({
  type: _type,
  title,
  defaultValue,
  placeholder,
  onSubmit,
  onClose,
}: ChangeDataModalProps) => {
  const { t } = useTranslation();
  const type = _type as 'name' | 'e-mail';

  const RecieverValidationSchema =
    type === 'name'
      ? z.object({
          name: z
            .string()
            .min(1, { message: (() => t('pleaseEnterRecieversName'))() })
            .regex(textRegex, { message: t('invalidCharacters') })
            .default(type === 'name' ? defaultValue : ''),
        })
      : z.object({
          'e-mail': z
            .string()
            .min(1, { message: t('pleaseEnterEmail') })
            .email({ message: t('incorrectEmail') })
            .default(type === 'e-mail' ? defaultValue : ''),
        });

  type RecieverSchema = z.infer<typeof RecieverValidationSchema>;

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<RecieverSchema>({
    resolver: zodResolver(RecieverValidationSchema),
  });

  useEffect(() => {
    setValue(type, defaultValue);
  }, [defaultValue]);

  return (
    <Modal isShow className={styles.modal}>
      <form
        className={styles.container}
        onSubmit={handleSubmit((data) =>
          onSubmit(data[type as keyof RecieverSchema], type),
        )}
      >
        <div className={styles.header}>
          {title}
          <button
            className={classes(['button', styles.close])}
            onClick={onClose}
          >
            <Icon type="close" size={32} />
          </button>
        </div>
        <label className={styles.label}>
          <input
            {...register(type)}
            className={styles.input}
            placeholder={placeholder}
          />
          <button
            className="button _no-padding"
            onClick={() => setValue(type, '')}
            type="button"
          >
            <Icon type="close" size={24} />
          </button>
        </label>
        <div className={styles.error}>
          {(errors[type as keyof RecieverSchema] as FieldError)?.message}
        </div>
        <button
          type="submit"
          className={classes(['button _primary', styles.button])}
        >
          {t('apply')}
        </button>
      </form>
    </Modal>
  );
};
