import { classes } from 'html-classes';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import { company } from '~/company/Company';
import { ReferralProgramMenuItem } from '~/components/ReferralProgramMenuItem';
import { REFERRAL_PROGRAM_PAGE_URL } from '~/components/ReferralProgramMenuItem/constants';
import { useModal } from '~/hooks/useModal';
import { mainStore } from '~/stores/MainStore';
import { userStore } from '~/stores/UserStore';

import Icon from '../Icon/Icon';
import { ModalType } from '../Modal/interface';

const MainSidebar = observer(() => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { openModal } = useModal();

  const handleDeliveryAddress = () => openModal(ModalType.Logout);

  return (
    <>
      <section
        className="sidebar-section sidebar-section-header"
        data-company={company.name}
      >
        <p className="sidebar-section-header__title">{userStore.fullName}</p>
        <p className="sidebar-section-header__phone phoneNumber">
          {mainStore.prettyPhoneNumber(userStore.personalData.phone || '')}
        </p>
        {company.match({
          citydrinks: (
            <Link
              to="/cabinet/personal-data"
              className="sidebar-section-header__button button _bordered"
            >
              <Icon type="filter" size={24} />
              {t('editProfile')}
            </Link>
          ),
        })}
      </section>
      <section className="sidebar-section" data-company={company.name}>
        <Link
          to={company.match({
            citydrinks: '/cabinet',
            default: '/cabinet/personal-data',
          })}
          className={classes([
            'sidebar-section-item',
            (pathname === '/cabinet' ||
              pathname === '/cabinet/personal-data') &&
              'active',
          ])}
        >
          <Icon type="menu-personal" size={24} />
          <p className="sidebar-section-item__text">
            {company.match({
              citydrinks: t('cabinet'),
              default: t('personalData'),
            })}
          </p>
        </Link>
        <Link
          to="/cabinet/orders"
          className={classes([
            'sidebar-section-item',
            pathname.startsWith('/cabinet/orders') && 'active',
          ])}
        >
          <Icon type="orders" size={24} />
          <p className="sidebar-section-item__text">{t('orders')}</p>
        </Link>
        <Link
          to="/cabinet/address"
          className={classes([
            'sidebar-section-item',
            pathname === '/cabinet/address' && 'active',
          ])}
        >
          <Icon type="location" size={24} />
          <p className="sidebar-section-item__text">{t('deliveryAddress')}</p>
        </Link>
        <Link
          to="/cabinet/payment-method"
          className={classes([
            'sidebar-section-item',
            pathname === '/cabinet/payment-method' && 'active',
          ])}
        >
          <Icon type="cards" size={24} />
          <p className="sidebar-section-item__text">{t('paymentMethod')}</p>
        </Link>
        {/* <Link to="/cabinet/events" className={classes(
          ['sidebar-section-item', pathname === '/cabinet/events' && 'active'])}>
          <Icon type="calendar" size={24} />
          <p className="sidebar-section-item__text">{t('myEvents')}</p>
          <div className="sidebar-section-item__count">7</div>
        </Link>
        <Link to="/cabinet/subscriptions" className={classes(
          ['sidebar-section-item', pathname === '/cabinet/subscriptions' && 'active'])}>
          <Icon type="subscription" size={24} />
          <p className="sidebar-section-item__text">{t('subscriptions')}</p>
          <div className="sidebar-section-item__count">444</div>
        </Link>
        <Link to="/cabinet/notifications" className={classes(
          ['sidebar-section-item', pathname === '/cabinet/notifications' && 'active'])}>
          <Icon type="notification" size={24} />
          <p className="sidebar-section-item__text">{t('notifications')}</p>
          <div className="sidebar-section-item__count">3</div>
        </Link> */}
      </section>
      <section className="sidebar-section" data-company={company.name}>
        <ReferralProgramMenuItem
          className={classes([
            'sidebar-section-item',
            pathname === REFERRAL_PROGRAM_PAGE_URL && 'active',
          ])}
          labelClassName="sidebar-section-item__text"
        />
        {!company.hideAboutUs && (
          <>
            <Link
              to="/our-story"
              className={classes([
                'sidebar-section-item',
                pathname === '/our-story' && 'active',
              ])}
            >
              <Icon type="link" size={24} />
              <p className="sidebar-section-item__text">{t('aboutUs')}</p>
            </Link>
            <Link
              to="/contact-us"
              className={classes([
                'sidebar-section-item',
                pathname === '/cabinet/contact' && 'active',
              ])}
            >
              <Icon type="message" size={24} />
              <p className="sidebar-section-item__text">{t('contactUs')}</p>
            </Link>
          </>
        )}
        {/*<Link to="/cabinet/rate" className={classes(*/}
        {/*  ['sidebar-section-item', pathname === '/cabinet/rate' && 'active'])}>*/}
        {/*  <Icon type="star" size={24} />*/}
        {/*  <p className="sidebar-section-item__text">{t('rateUs')}</p>*/}
        {/*</Link>*/}
        <Link
          to="/legals"
          state={{ source: 'cabinet' }}
          className={classes([
            'sidebar-section-item',
            pathname === '/cabinet/terms' && 'active',
          ])}
        >
          <Icon type="document" size={24} />
          <p className="sidebar-section-item__text">
            {t(company.isDefaultCompany ? 'legals' : 'termsAndConditions')}
          </p>
        </Link>
        {/* <a
          href={company.config.links.affiliateProgram}
          target="blank"
          className="sidebar-section-item"
        >
          <Icon type="people" size={24} />
          <p className="sidebar-section-item__text">{t('affiliates')}</p>
        </a> */}
      </section>
      <section className="sidebar-section" data-company={company.name}>
        <button
          className="button no-padding item sidebar-section__logout"
          onClick={handleDeliveryAddress}
        >
          <Icon type="logout" size={24} />
          {t('logOut')}
        </button>
      </section>
    </>
  );
});

export default MainSidebar;
