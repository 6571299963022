import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { company } from '~/company/Company';
import { IMAGES } from '~/constants/images';
import { useCategoryAnalytics } from '~/hooks/useCategoryAnalytics';
import { useGlobal } from '~/hooks/useGlobal';
import { useStores } from '~/hooks/useStore';
import { Category } from '~/stores/CategoriesStore';

import Collapsible from '../Collapsible/Collapsible';
import Icon from '../Icon/Icon';

import styles from './LayoutLocalee.module.scss';

export const LayoutLocalee = observer(() => {
  const { t } = useTranslation();
  const { handleClickCategory } = useCategoryAnalytics();
  const { isMobile } = useGlobal();
  const { shopCategories } = useStores();

  const startShoppingSubcategories = shopCategories.list.reduce((acc, item) => {
    return [...acc, ...(item ? item.subcategory : [])];
  }, [] as Category[]);

  return (
    <footer className={styles.footer} data-company={company.name}>
      <div className="content-layout">
        <div className={styles.primaryInfo}>
          <Link to="/">
            <img
              className={styles.primaryInfoLogo}
              src={IMAGES.logo.light}
              alt=""
            />
          </Link>
          <div className={styles.qrContainer}>
            <div className={styles.qrContainerApp}>
              <div className={styles.qrContainerAppItem}>
                <a
                  href={company.config.appStoreUrl.ios}
                  className={styles.qrContainerAppItemIcon}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon type="apple" size={28} className={styles.apple} />
                </a>
                <p className={styles.qrContainerAppItemText}>App Store</p>
              </div>
              <div className={styles.qrContainerAppItem}>
                <a
                  href={company.config.appStoreUrl.android}
                  className={styles.qrContainerAppItemIcon}
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icon type="gplay" size={28} />
                </a>
                <p className={styles.qrContainerAppItemText}>Google Play</p>
              </div>
            </div>
            <div className={styles.qrContainerDescription}>
              {t(
                isMobile ? 'phrases:downloadOurApp' : 'phrases:pointYourCamera',
              )}
            </div>
            <img
              src={IMAGES.qr.maxi}
              alt=""
              className={styles.qrContainerImg}
            />
          </div>
        </div>

        <div className={styles.secondaryInfo}>
          <div className={styles.contacts}>
            <p className={styles.title}>{t('contacts')}</p>
            {company.config.phoneNumber && (
              <div className={styles.contactsItem}>
                <p className={styles.contactsItemTitle}>{t('hotline')}</p>
                <a
                  href={`tel:${company.config.phoneNumber}`}
                  className={styles.contactsItemPhone}
                >
                  {company.config.phoneNumber}
                </a>
              </div>
            )}
            {company.config.email && (
              <div className={styles.contactsItem}>
                <a
                  href={`mailto:${company.config.email}`}
                  className={styles.contactsItemTitle}
                >
                  {company.config.email}
                </a>
              </div>
            )}
            <div className={styles.contactsSocial}>
              {company.config.links.instagram && (
                <a
                  href={company.config.links.instagram}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.contactsSocialItem}
                >
                  <Icon type="insta" size={20} />
                </a>
              )}
              {company.config.links.facebook && (
                <a
                  href={company.config.links.facebook}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.contactsSocialItem}
                >
                  <Icon type="fb" size={20} />
                </a>
              )}
              {company.config.links.tiktok && (
                <a
                  href={company.config.links.tiktok}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.contactsSocialItem}
                >
                  <Icon type="tiktok" size={20} />
                </a>
              )}
              {company.config.links.youtube && (
                <a
                  href={company.config.links.youtube}
                  target="_blank"
                  rel="noreferrer"
                  className={styles.contactsSocialItem}
                >
                  <Icon type="youtube" size={20} />
                </a>
              )}
            </div>
          </div>

          <div className={styles.menu}>
            <div className={styles.menuCol}>
              {isMobile ? (
                <>
                  <Collapsible
                    title={t('popularCategories')}
                    isCollapsed={true}
                    innerClassName={styles.menuColInner}
                    titleTextClassName={styles.menuColTitle}
                    titleIconClassName={styles.menuColTitleIcon}
                  >
                    {startShoppingSubcategories.map(({ id, name }) => (
                      <div className={styles.menuColItem} key={id}>
                        <Link
                          to={`/c/${id}`}
                          onClick={handleClickCategory(id, 'footer')}
                        >
                          {name}
                        </Link>
                      </div>
                    ))}
                  </Collapsible>
                </>
              ) : (
                <>
                  <div className={styles.menuColTitle}>
                    {t('popularCategories')}
                  </div>
                  {startShoppingSubcategories.map(({ id, name }) => (
                    <div className={styles.menuColItem} key={id}>
                      <Link
                        to={`/c/${id}`}
                        onClick={handleClickCategory(id, 'footer')}
                      >
                        {name}
                      </Link>
                    </div>
                  ))}
                </>
              )}
            </div>
            <div className={styles.menuCol}>
              {isMobile ? (
                <>
                  <Collapsible
                    title={t('company')}
                    isCollapsed={true}
                    innerClassName={styles.menuColInner}
                    titleTextClassName={styles.menuColTitle}
                    titleIconClassName={styles.menuColTitleIcon}
                  >
                    <div className={styles.menuColItem}>
                      <Link to={'/legals'} state={{ source: 'home' }}>
                        {t('legals')}
                      </Link>
                    </div>
                  </Collapsible>
                </>
              ) : (
                <>
                  <div className={styles.menuColTitle}>{t('company')}</div>
                  <div className={styles.menuColItem}>
                    <Link to={'/legals'} state={{ source: 'home' }}>
                      {t('legals')}
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className={styles.bottom}>
          <div className={styles.copyright}>
            &copy; {company.config.license.name}
          </div>
          <div className={styles.license}>
            {company.config.license.numberPrinary}
            {company.config.license.numberSecondary && (
              <>
                <br />
                {company.config.license.numberSecondary}
              </>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
});
